import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import MainNav from 'components/functional/mainNav'
import SingleNews from 'components/functional/singleNews'
import SmallTitle from 'components/functional/smallTitle'
import Layout from 'components/layout'

const metaData = {
  title: 'Opinion | D E W Y N T E R S',
  description: `We’re Dewynters, the leading integrated live entertainment advertising agency. Based in London, we create best in class campaigns for theatre, immersive, festivals and exhibitions. | Opinion`
}

const NewsBody = styled.section`
  width: 100%;
  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 620px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }
`
const LatestNews = ({ data }) => {
  const news = data.News.edges
  return (
    <Layout title={metaData.title} description={metaData.description}> 
      <SmallTitle title="Latest News" dnd />
      <MainNav alt />
      <NewsBody>
        <div className="news">
          {news.map((post, i) => (
            <SingleNews key={i} data={post} />
          ))}
        </div>
      </NewsBody>
    </Layout>
  )
}

LatestNews.propTypes = {
  data: PropTypes.shape({
    News: PropTypes.any,
  }),
}

export default LatestNews

export const NewsQuery = graphql`
  query NewsQuery {
    News: allPostsJson {
      edges {
        node {
          title
          description
          date
          link
          local
          href
          image {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
          }
          video
          body
        }
      }
    }
  }
`
